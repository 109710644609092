<template>
  <div class="base-timer h-32 w-32">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      stroke-width="1.5"
    >
      <g class="base-timer__circle" fill="none" stroke="none">
        <path
          v-if="timeLeft"
          id="base-timer-path-remaining"
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining text-off-white"
          d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
        ></path>
      </g>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

const props = defineProps({
  limit: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["finished"]);

const FULL_DASH_ARRAY = 283;
const circleDasharray = ref(`${FULL_DASH_ARRAY} ${FULL_DASH_ARRAY}`);
let timePassed = 0;
const timeLeft = ref(props.limit);
let timerInterval = ref<any>(0);

onMounted(() => {
  startTimer();
});

function onTimesUp() {
  clearInterval(timerInterval.value);
  setTimeout(() => emit("finished"), 500);
}

function startTimer() {
  timerInterval.value = setInterval(() => {
    timePassed = timePassed += 1;
    timeLeft.value = props.limit - timePassed;

    const newValue = calculateTimeFraction() * FULL_DASH_ARRAY;
    // circleDasharray.value = `${newValue < 0 ? 0 : newValue} ${FULL_DASH_ARRAY}`;
    circleDasharray.value = `${
      calculateTimeFraction() * FULL_DASH_ARRAY
    } ${FULL_DASH_ARRAY}`;

    if (timeLeft.value <= 0) {
      onTimesUp();
    }
  }, 1000);
}

function calculateTimeFraction() {
  const rawTimeFraction = timeLeft.value / props.limit;
  return rawTimeFraction - (1 / props.limit) * (1 - rawTimeFraction);
}
</script>

<style lang="scss">
.base-timer {
  position: relative;
  width: 300px;
  height: 300px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__path-remaining {
  stroke-width: 3px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}
</style>
