<template>
  <label
    class="relative flex flex-col justify-center"
    :class="[
      big ? 'h-full rounded-md' : 'rounded-sm',
      dragging && 'brightness-125',
      !icon &&
        'w-full border-2 border-dashed border-dark-lighter bg-dark transition-all hover:brightness-125 cursor-pointer',
    ]"
    data-test="file-input-dropzone"
    @dragenter="dragging = true"
    @dragleave="dragging = false"
    @dragover="$event.preventDefault()"
    @drop="dropFiles"
    @click="handleClick"
  >
    <div ref="inputContainer"></div>

    <div
      v-if="big && !icon"
      class="absolute top-20 left-1/2 -translate-x-1/2 flex justify-center w-full text-14 text-gray"
    >
      <div class="text-center">
        {{ hint }}
      </div>
    </div>

    <div
      v-if="!icon"
      class="flex justify-center items-center pointer-events-none"
      :class="big ? 'flex-col gap-20' : 'gap-4'"
    >
      <slot>
        <IconAttachment
          class="shrink-0"
          :class="big ? 'h-[90px] w-[90px]' : 'h-40 w-40 mr-16'"
        />

        <div class="py-16" :class="big && 'flex flex-col items-center'">
          <div class="font-bold">
            {{ $t("ui.fileInput.dragndrop") }}
          </div>

          <div>
            {{ $t("ui.fileInput.or") }}
            <span class="underline">
              {{ $t("ui.fileInput.click") }}
            </span>
          </div>
        </div>
      </slot>
    </div>

    <div
      v-if="icon"
      class="flex items-center justify-center rounded-full p-6 transition-all hover:bg-hover cursor-pointer"
    >
      <IconAttachment class="h-16 w-16" />
    </div>

    <p
      v-if="!pristine && error && !icon"
      class="text-12 text-center tracking-wide text-error font-bold whitespace-nowrap mt-8"
    >
      {{ error }}
    </p>
  </label>
</template>

<script setup lang="ts">
import { onMounted, PropType, ref } from "vue";
import { validate, ValidatorFn } from "~/components/ui/form/validation";
import { useT } from "~/locales/i18n";
import { bindToForm } from "../form-bind";

import IconAttachment from "~/assets/icons/icon-attachment.vue";

const props = defineProps({
  value: {
    type: Array as PropType<File[]>,
    required: true,
  },
  accept: {
    type: String,
    required: true,
  },
  validation: {
    type: Array as PropType<ValidatorFn[] | undefined>,
    default: undefined,
  },
  hint: {
    type: String,
    default: () => useT().t("ui.fileInput.hint"),
  },
  big: {
    type: Boolean,
    default: true,
  },
  icon: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  allowMultipleFiles: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:value"]);

const dragging = ref(false);

const x = ref("");
const error = ref("");
const pristine = ref(true);
const disabledByForm = ref(false);
bindToForm({
  error: x,
  disabledByForm,
});

function handleClick(e: Event) {
  if (props.disabled) {
    e.stopPropagation();
  }
}

function extractFiles(target: DataTransfer | HTMLInputElement): File[] {
  return target.files ? [...(target.files as any)] : [];
}

function dropFiles(e: DragEvent) {
  e.preventDefault();
  dragging.value = false;

  if (!e.dataTransfer) {
    return;
  }

  update(extractFiles(e.dataTransfer));
}

function uploadFiles(e: Event) {
  if (!e.currentTarget) {
    return;
  }

  update(extractFiles(e.currentTarget as HTMLInputElement));
}

function update(files: File[]) {
  pristine.value = false;
  error.value = validate(
    { required: true, value: files, validation: props.validation },
    pristine.value
  );
  if (error.value) return;

  emit("update:value", files);

  replaceInput();
}

const inputContainer = ref<HTMLInputElement | null>(null);
function replaceInput() {
  const container = inputContainer.value;
  if (!container) {
    return;
  }

  while (container.lastElementChild) {
    container.removeChild(container.lastElementChild);
  }

  container.appendChild(createInput());
}
function createInput() {
  const input = document.createElement("input");

  input.classList.add("hidden");
  input.type = "file";
  input.accept = props.accept;
  input.multiple = props.allowMultipleFiles;
  input.addEventListener("input", (event) => uploadFiles(event));

  return input;
}

onMounted(() => {
  replaceInput();
});
</script>
