import { ValidatorFn, ValidatorResponse } from "./validate";

export function maxLengthValidator(maxLength: number): ValidatorFn {
  return (value: string): ValidatorResponse => {
    const response: ValidatorResponse = {
      errorKey: "error.max_length",
      arg: maxLength,
    };

    if (value.length <= maxLength) {
      response.errorKey = "";
      response.arg = undefined;
    }

    return response;
  };
}
