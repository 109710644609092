import { defineStore } from "pinia";

interface GeneralStore {
  isMobile: boolean;
  pwaInstalled: boolean;
}

export const useGeneralStore = defineStore("general", {
  state: (): GeneralStore => ({
    isMobile: false,
    pwaInstalled: false,
  }),

  actions: {
    async init(window: Window, screen: Screen) {
      this.isMobile = screen.width < 1240;
      this.pwaInstalled = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;
    },
  },
});
