import {
  Achievement,
  PublicProfile,
} from "~/components/pages/profile/profile.types";
import { Database } from "./db.types";

export type ReportReason = Database["public"]["Enums"]["report_reason"];
export type ReportAction = Database["public"]["Enums"]["report_action"];
export type CommentReportReason = Omit<ReportReason, "repost">;

export interface Vote {
  profiles: Omit<PublicProfile, "avatarPath" | "bio" | "points">;
  upvote: boolean;
}

export type MemeCategory = Database["public"]["Enums"]["meme_category"];
export const memeCategories: MemeCategory[] = [
  "topkek",
  "good",
  "edgy",
  "fresh",
  "whatever",
  "bad",
  "garbage",
  "imported",
];

type SupabaseComment =
  Database["public"]["Functions"]["get_comments"]["Returns"][0];
export interface UserComment
  extends Omit<SupabaseComment, "userAchievement" | "userVote"> {
  userAchievement?: Achievement;
  userVote: boolean | null;
}

type SupabasePost = Database["public"]["Functions"]["get_posts"]["Returns"][0];
export interface Post
  extends Omit<SupabasePost, "userAchievement" | "userVote"> {
  userAchievement?: Achievement;
  userVote: boolean | null;
}

export interface HugelolPostData {
  id: number;
}

export interface PostFilter {
  username?: string;
  categories?: MemeCategory[];
  vote?: VoteFilter;
}

export interface CommentFilter {
  username?: string;
  postId?: string;
  vote?: VoteFilter;
}

export interface VoteFilter {
  username: string;
  userVote: boolean;
}
