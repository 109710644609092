import {
  getCurrentInstance,
  inject,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  ref,
  Ref,
} from "vue";

export interface InputWatcher {
  id: number;
  error: string;
}

export interface IFormInput {
  error: Ref<string>;
  updateCount: Ref<number>;
  disabledByForm: Ref<boolean>;
  uid: number;
}

export interface IForm {
  bind(component: IFormInput): void;
  unbind(uid: number): void;
  isValid: Ref<boolean>;
}

export function bindToForm(args: {
  error: Ref<string>;
  disabledByForm: Ref<boolean>;
}) {
  const form = inject("form") as IForm;
  const instanceId = getCurrentInstance()?.uid || -1;
  const updateCount = ref(0);

  onMounted(() => {
    form.bind({
      error: args.error,
      updateCount,
      disabledByForm: args.disabledByForm,
      uid: instanceId,
    });
  });

  onUpdated(() => {
    updateCount.value += 1;
  });

  onBeforeUnmount(() => {
    form.unbind(instanceId);
  });
}
