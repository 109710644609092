<template>
  <header
    class="absolute lg:sticky top-0 left-0 z-[99] flex justify-center w-full lg:mb-48"
  >
    <nav class="w-full lg:max-w-[77rem] xl:max-w-[102rem] 2xl:max-w-[125rem]">
      <div
        v-if="!isMobile"
        class="flex justify-between w-full p-20 bg-[#31333894] backdrop-blur backdrop-filter border border-dark-lighter rounded-b-lg shadow-xl"
      >
        <div class="flex justify-center items-center gap-64">
          <Link url="memes">
            <div class="flex justify-center items-center rounded-sm px-8 py-2">
              <Gigalol class="h-24" />
            </div>
          </Link>

          <div v-if="!isMobile" class="flex justify-center items-center gap-24">
            <div
              class="flex items-center justify-center gap-8 font-bold px-20 py-8 rounded-md transition-colors hover:bg-dark-lighter cursor-pointer group"
              @click="reloadMemes()"
            >
              {{ $t("x.memes") }}

              <IconRefresh
                class="h-16 w-16 transition-all group-hover:rotate-[180deg]"
              />
            </div>
          </div>
        </div>

        <div
          v-if="!auth.loading && !profile.loading"
          class="flex items-center gap-16"
        >
          <template v-if="!auth.authenticated">
            <Link url="signup" :underline="false">
              <Button>
                {{ $t("header.signup") }}
              </Button>
            </Link>

            <Link url="login" :underline="false">
              <Button variant="secondary">
                {{ $t("header.login") }}
              </Button>
            </Link>
          </template>

          <template v-else>
            <PostMemesButton />

            <Dropdown>
              <template #toggle>
                <HeaderAvatar />
              </template>

              <template #dropdown>
                <DropdownItem
                  :link="{
                    url: 'user-detail',
                    params: { username: profile.username },
                  }"
                >
                  <IconProfile class="h-16 w-16" />

                  {{ $t("header.menu.profile") }}
                </DropdownItem>

                <DropdownItem
                  v-if="profile.isModerator"
                  :link="{ url: 'moderation' }"
                >
                  <NumberBubble
                    v-if="moderation.notificationsAmount"
                    :amount="moderation.notificationsAmount"
                    class="bg-green -m-2"
                  />

                  <IconModeration v-else class="h-16 w-16" />

                  {{ $t("header.menu.moderation") }}
                </DropdownItem>

                <DropdownItem v-if="profile.isAdmin" :link="{ url: 'admin' }">
                  <IconWarning class="h-16 w-16" />

                  {{ $t("header.menu.admin") }}
                </DropdownItem>

                <DropdownItem :link="{ url: 'notifications' }">
                  <NotificationBubble
                    v-if="profile.unreadNotifications"
                    :amount="profile.unreadNotifications"
                    class="-m-2"
                  />

                  <IconNotifications v-else class="h-16 w-16" />

                  {{ $t("header.menu.notifications") }}
                </DropdownItem>

                <DropdownItem :link="{ url: 'settings' }">
                  <IconSettings class="h-16 w-[18px] -ml-2" />

                  {{ $t("header.menu.settings") }}
                </DropdownItem>

                <DropdownItem :link="{ url: 'about' }">
                  <IconHelp class="h-16 w-[18px] -ml-2" />

                  {{ $t("header.menu.about") }}
                </DropdownItem>

                <DropdownItem @click="auth.logout()" data-test="logout-button">
                  <IconLogout class="h-16 w-16" />

                  {{ $t("header.menu.logout") }}
                </DropdownItem>
              </template>
            </Dropdown>
          </template>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useProfile } from "~/components/pages/profile/+profile.store";
import { useAuth } from "~/plugins/auth";
import { useModeration } from "../pages/moderation/+moderation.store";

import Gigalol from "~/assets/icons/gigalol.vue";
import IconHelp from "~/assets/icons/icon-help.vue";
import IconLogout from "~/assets/icons/icon-logout.vue";
import IconModeration from "~/assets/icons/icon-moderation.vue";
import IconNotifications from "~/assets/icons/icon-notifications.vue";
import IconProfile from "~/assets/icons/icon-profile.vue";
import IconRefresh from "~/assets/icons/icon-refresh.vue";
import IconSettings from "~/assets/icons/icon-settings.vue";
import IconWarning from "~/assets/icons/icon-warning.vue";
import PostMemesButton from "~/components/memes/post-memes-button.vue";
import Link from "~/components/shared/link.vue";
import Button from "~/components/ui/button.vue";
import DropdownItem from "~/components/ui/dropdown/dropdown-item.vue";
import Dropdown from "~/components/ui/dropdown/dropdown.vue";
import NotificationBubble from "../pages/notifications/notification-bubble.vue";
import NumberBubble from "../shared/number-bubble.vue";
import HeaderAvatar from "./header-avatar.vue";

const moderation = useModeration();
const profile = useProfile();

const auth = useAuth();

const isMobile = computed(() => screen.width < 640);

const route = useRoute();
const routeName = computed(() => route.name);
const router = useRouter();
function reloadMemes() {
  if (routeName.value === "memes") {
    router.go(0);
  } else {
    router.push({ name: "memes" });
  }
}
</script>
