import { Session } from "@supabase/supabase-js";
import axios from "axios";

const API_URL = import.meta.env.VITE_API_URL || "api";

export function useAxios() {
  return axios.create({
    baseURL: API_URL,
  });
}

export function useAuthAxios(session?: Session) {
  const token = session?.access_token || "";
  if (!token) {
    throw new Error("cannot use auth axios without valid session");
  }

  return axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: token,
    },
  });
}
