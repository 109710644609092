<template>
  <div
    id="notifications"
    class="fixed top-8 left-1/2 -translate-x-1/2 lg:translate-x-0 lg:top-auto lg:left-auto lg:bottom-24 lg:right-12 z-[2000] flex flex-col gap-8 w-full lg:w-fit px-8 lg:px-0"
  >
    <Notification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useNotificationStore } from "./+notification.store";

import Notification from "./notification.vue";

const notifications = computed(() => useNotificationStore().notifications);
</script>
