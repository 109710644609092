import { defineStore } from "pinia";
import { Database } from "~/lib/db/db.types";
import {
  Post,
  ReportAction,
  ReportReason,
  UserComment,
} from "~/lib/db/post.types";
import { useSupabase } from "~/plugins/supabase";

export type ReportedPost =
  Database["public"]["Functions"]["get_reported_posts"]["Returns"][0];
export type ReportedComment =
  Database["public"]["Functions"]["get_reported_comments"]["Returns"][0];

export interface PostReports {
  post: Post;
  reports: ReportedPost[];
}
export interface CommentReports {
  comment: UserComment;
  reports: ReportedComment[];
}

interface Moderation {
  reportedPosts: PostReports[];
  reportedPostsCount: number;

  reportedComments: CommentReports[];
  reportedCommentsCount: number;
}

export const useModeration = defineStore("moderation", {
  state: (): Moderation => ({
    reportedPosts: [],
    reportedPostsCount: 0,

    reportedComments: [],
    reportedCommentsCount: 0,
  }),

  getters: {
    notificationsAmount(): number {
      return this.reportedPostsCount + this.reportedCommentsCount;
    },
  },

  actions: {
    async loadNotifications() {
      const { data } = await useSupabase()
        .client.rpc("get_moderation_notifications")
        .select()
        .limit(1)
        .single();
      if (!data) return;

      this.reportedPostsCount = data.reported_posts;
      this.reportedCommentsCount = data.reported_comments;
    },

    async getReportedPosts() {
      const { data: reports } = await useSupabase()
        .client.rpc("get_reported_posts")
        .order("createdAt", {
          ascending: false,
        })
        .select()
        .throwOnError();
      if (!reports) return;

      const { data: posts } = await useSupabase()
        .client.rpc("get_posts", { post_ids: reports.map((r) => r.postId) })
        .throwOnError()
        .select();

      if (!posts) return;

      for (const post of posts) {
        this.reportedPosts.push({
          post: post as unknown as Post,
          reports: reports.filter((r) => r.postId === post.id),
        });
      }
    },

    async getReportedComments() {
      const { data: reports } = await useSupabase()
        .client.rpc("get_reported_comments")
        .order("createdAt", { ascending: false })
        .select()
        .throwOnError();
      if (!reports) return { reports: undefined, comments: undefined };

      const { data: comments } = await useSupabase()
        .client.rpc("get_comments", {
          comment_ids: reports.map((r) => r.commentId),
        })
        .throwOnError()
        .select();

      if (!comments) return;

      for (const comment of comments) {
        this.reportedComments.push({
          comment: comment as unknown as UserComment,
          reports: reports.filter((r) => r.commentId === comment.id),
        });
      }
    },

    async resolveReport(
      type: "post" | "comment",
      thingId: string,
      moderatorMessage: string,
      moderatorAction: ReportAction,
      moderatorReason: ReportReason | null
    ) {
      await useSupabase()
        .client.rpc("resolve_report", {
          type,
          thing_id: thingId,
          moderator_message: moderatorMessage,
          moderator_action: moderatorAction,
          moderator_reason: moderatorReason as any,
        })
        .throwOnError();

      this.removeReport(type, thingId);
    },

    async markPostAsNSFW(postId: string, moderatorMessage: string) {
      await this.resolveReport(
        "post",
        postId,
        moderatorMessage,
        "marked_as_nsfw",
        "nsfw"
      );

      await useSupabase()
        .client.rpc("mark_post_as_nsfw", { post_id: postId })
        .throwOnError();

      this.removeReport("post", postId);
    },

    async deletePost(
      postId: string,
      memePath: string,
      moderatorMessage: string,
      reason: ReportReason
    ) {
      await this.resolveReport(
        "post",
        postId,
        moderatorMessage,
        "deleted",
        reason
      );

      await useSupabase().deleteMeme(memePath);

      await useSupabase()
        .client.rpc("delete_post_as_mod", { post_id: postId, reason })
        .throwOnError();

      this.removeReport("post", postId);
    },

    async deleteComment(
      commentId: string,
      memePath: string,
      moderatorMessage: string,
      reason: ReportReason
    ) {
      await this.resolveReport(
        "comment",
        commentId,
        moderatorMessage,
        "deleted",
        reason
      );

      await useSupabase().deleteMeme(memePath);

      await useSupabase()
        .client.rpc("delete_comment_as_mod", { comment_id: commentId })
        .throwOnError();

      this.removeReport("comment", commentId);
    },

    async markCommentAsNSFW(commentId: string, moderatorMessage: string) {
      await this.resolveReport(
        "comment",
        commentId,
        moderatorMessage,
        "marked_as_nsfw",
        "nsfw"
      );

      await useSupabase()
        .client.rpc("mark_comment_as_nsfw", { comment_id: commentId })
        .throwOnError();

      this.removeReport("comment", commentId);
    },

    async markPostAsRepost(
      postId: string,
      memePath: string,
      moderatorMessage: string
    ) {
      await this.resolveReport(
        "post",
        postId,
        moderatorMessage,
        "repost",
        "repost"
      );

      await useSupabase().deleteMeme(memePath);

      await useSupabase()
        .client.rpc("delete_post_as_mod", { post_id: postId, reason: "repost" })
        .throwOnError();

      this.removeReport("post", postId);
    },

    removeReport(type: "post" | "comment", thingId: string) {
      let index =
        type === "post"
          ? this.reportedPosts.findIndex((p) => p.post.id === thingId)
          : this.reportedComments.findIndex((c) => c.comment.id === thingId);
      if (index === -1) return;

      if (type === "post") {
        this.reportedPosts.splice(index, 1);
        this.reportedPostsCount -= 1;
      } else {
        this.reportedComments.splice(index, 1);
        this.reportedCommentsCount -= 1;
      }
    },
  },
});
