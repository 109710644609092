import { Component, computed } from "vue";
import IconRefresh from "~/assets/icons/icon-refresh.vue";
import { useAuth } from "~/plugins/auth";
import { useProfile } from "../pages/profile/+profile.store";

export interface HeaderRoute {
  icon?: Component;
  label: string;
  description?: string;
  url?: string;
  params?: any;
  children?: HeaderRoute[];
}

export const headerRoutes = computed((): HeaderRoute[] => {
  const routes: HeaderRoute[] = [
    {
      label: "Memes",
      url: "memes",
      icon: IconRefresh,
    },
  ];

  if (useAuth().authenticated) {
    routes.push(
      ...[
        {
          label: "Profile",
          url: "user-detail",
          params: {
            username: useProfile().username || "",
          },
        },
        {
          label: "Notifications",
          url: "notifications",
        },
        {
          label: "Settings",
          url: "settings",
        },
      ]
    );
  }

  if (useProfile().isModerator) {
    routes.push({
      label: "Moderation",
      url: "moderation",
    });
  }

  routes.push({
    label: "About",
    url: "about",
  });

  return routes;
});
