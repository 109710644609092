<template>
  <div
    class="fixed top-0 left-0 flex justify-center w-screen h-full md:h-screen bg-dark z-[1000]"
  >
    <div
      class="flex flex-col gap-16 h-full max-h-full w-full max-w-full p-8 lg:p-32 lg:max-w-[75rem] overflow-hidden"
    >
      <div class="flex justify-between items-center">
        <h1 class="mb-0">{{ $t("newMemeModal.title") }}</h1>

        <IconButton @click="newMemesStore.showModal = false">
          <IconClose />
        </IconButton>
      </div>

      <Form
        v-model:is-valid="newMemesStore.form.isValid"
        error=""
        class="grow overflow-hidden"
        @submit="uploadMemes()"
      >
        <div class="flex flex-col gap-16 h-full max-h-full lg:overflow-hidden">
          <div
            class="grow flex flex-col w-full overflow-y-auto lg:pr-0 lg:overflow-hidden"
            :class="newMemesStore.form.memes.length && 'pr-8'"
          >
            <div class="grow lg:overflow-hidden">
              <div class="flex flex-col justify-between gap-16 h-full">
                <FileInput
                  v-if="!touched"
                  :value="[]"
                  @update:value="newMemesStore.addMemeFiles"
                  :accept="memeExtensions"
                  :validation="[fileSizeValidator(20)]"
                  class="h-full"
                />

                <div
                  v-else
                  class="flex flex-col justify-between h-full lg:overflow-hidden gap-32"
                >
                  <div
                    class="shrink flex flex-col lg:flex-row gap-32 lg:overflow-hidden"
                  >
                    <div
                      v-if="newMemesStore.currentMeme"
                      class="shrink-0 flex flex-col gap-24 lg:max-w-512 w-full max-h-full overflow-hidden mt-2 mx-2"
                    >
                      <Input
                        ref="memeTitleInput"
                        v-model:value="newMemesStore.currentMeme.title"
                        :label="$t('newMemeModal.form.title.label')"
                        :placeholder="$t('newMemeModal.form.title.placeholder')"
                        :validation="[maxLengthValidator(128)]"
                        :autofocus="true"
                        class="shrink-0"
                        data-test="post-title-input"
                      />

                      <div class="flex items-center gap-8 mt-8">
                        <Checkbox
                          :selected="
                            newMemesStore.currentMeme.tags.includes('nsfw')
                          "
                          @update:selected="markAsNSFW()"
                          class="w-fit"
                        >
                          {{ $t("newMemeModal.form.nsfw.label") }}

                          <span class="font-bold text-error">NSFW</span>
                        </Checkbox>

                        <Tooltip
                          :message="$t('newMemeModal.form.nsfw.tooltip')"
                        >
                          <IconHelp class="h-16 w-16" />
                        </Tooltip>
                      </div>

                      <div class="grow lg:overflow-hidden">
                        <div
                          class="flex justify-center border border-dark-lighter rounded-sm max-h-full"
                        >
                          <MemePreview
                            :file="newMemesStore.currentMeme.file"
                            class="h-fit lg:h-auto max-h-full"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-col grow h-full lg:overflow-hidden">
                      <div
                        class="flex justify-between text-12 text-gray mb-8 lg:pr-8"
                      >
                        <div class="flex">
                          <div class="w-80 ml-4 mr-8">
                            {{ $t("newMemeModal.labels.preview") }}
                          </div>

                          <div>
                            {{ $t("newMemeModal.labels.memeTitle") }}
                          </div>
                        </div>

                        <div
                          class="clickable-text"
                          @click="newMemesStore.removeMemes()"
                        >
                          {{
                            $t("newMemeModal.delete", [
                              newMemesStore.form.memes.length,
                            ])
                          }}
                        </div>
                      </div>

                      <div class="grow lg:overflow-auto lg:pr-8">
                        <div class="grow flex flex-col gap-8">
                          <MemeListItem
                            v-for="(meme, i) in newMemesStore.form.memes"
                            :key="i"
                            :meme="meme"
                            :index="i"
                            @select="focusMemeTitleInput()"
                          />

                          <FileInput
                            :value="[]"
                            @update:value="newMemesStore.addMemeFiles"
                            :accept="memeExtensions"
                            :validation="[fileSizeValidator(20)]"
                            :big="false"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="shrink-0 flex justify-end gap-8 w-full">
            <Button
              variant="secondary"
              class="grow lg:grow-0"
              @click="newMemesStore.showModal = false"
            >
              {{ $t("x.button.cancel") }}
            </Button>

            <Tooltip
              :show="!newMemesStore.allMemesAreReady"
              :message="
                $t('newMemeModal.unfinished', {
                  n: newMemesStore.form.memes.filter((m) => !m.title).length,
                })
              "
              class="grow lg:grow-0"
            >
              <Button
                type="submit"
                :loading="newMemesStore.loading"
                :disabled="!newMemesStore.formIsValid"
                class="w-full"
                data-test="submit-post-button"
              >
                {{
                  newMemesStore.loading
                    ? $t("newMemeModal.form.button.loading", {
                        n: newMemesStore.processedMemes,
                        total: newMemesStore.form.memes.length,
                      })
                    : $t("x.button.post")
                }}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useNewMemesStore } from "~/components/modal/meme/+new-memes.store";
import {
  fileSizeValidator,
  maxLengthValidator,
} from "~/components/ui/form/validation";
import { memeExtensions } from "~/lib/files/supported-extensions";
import { hideScrollbarOnMount } from "~/lib/scrolling";

import IconClose from "~/assets/icons/icon-close.vue";
import IconHelp from "~/assets/icons/icon-help.vue";
import Tooltip from "~/components/tooltip/tooltip.vue";
import Button from "~/components/ui/button.vue";
import Checkbox from "~/components/ui/checkbox/checkbox.vue";
import FileInput from "~/components/ui/form/file-input/file-input.vue";
import Form from "~/components/ui/form/form.vue";
import Input from "~/components/ui/form/input/input.vue";
import IconButton from "~/components/ui/icon-button.vue";
import MemeListItem from "./meme-list-item.vue";
import MemePreview from "./meme-preview.vue";

const route = useRoute();
const router = useRouter();
const newMemesStore = useNewMemesStore();

const touched = computed(() => newMemesStore.form.memes.length);

hideScrollbarOnMount();

function markAsNSFW() {
  if (!newMemesStore.currentMeme) return;

  const index = newMemesStore.currentMeme.tags.findIndex((v) => v === "nsfw");
  if (index !== -1) {
    newMemesStore.currentMeme.tags.splice(index, 1);
  } else {
    newMemesStore.currentMeme.tags.push("nsfw");
  }
}

const memeTitleInput = ref<typeof Input | null>(null);
function focusMemeTitleInput() {
  if (!memeTitleInput.value) return;

  memeTitleInput.value.focus();
}

async function uploadMemes() {
  if (newMemesStore.loading) return;

  await newMemesStore.postMemes(route, router);
}
</script>
