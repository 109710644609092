import { defineStore } from "pinia";
import { v4 } from "uuid";
import { useTO } from "~/locales/i18n";

export type NotificationType = "success" | "error";

interface NotificationCTA {
  url?: string;
  params?: any;
  message: string;
  callback?: Function;
}

export interface Notification {
  id: string;
  type: NotificationType;
  message: string;
  cta?: NotificationCTA;
  lifespan?: number;
}

interface MemeStore {
  notifications: Notification[];
}

export const useNotificationStore = defineStore("notifications", {
  state: (): MemeStore => ({
    notifications: [],
  }),

  actions: {
    addNotification(notification: Omit<Notification, "id">) {
      const id = v4();
      this.notifications.push({
        id,
        lifespan: notification.lifespan || 5,
        ...notification,
      });
    },

    removeNotification(id: string) {
      this.notifications = this.notifications.filter(
        (notification) => notification.id !== id
      );
    },

    addSuccessNotification(message: string, cta?: NotificationCTA) {
      this.addNotification({
        message,
        type: "success",
        cta,
      });
    },

    addErrorNotification(message?: string) {
      const { t } = useTO();

      this.addNotification({
        message: message || t("x.error"),
        type: "error",
      });
    },
  },
});
