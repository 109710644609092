import { createPinia } from "pinia";
import { createApp } from "vue";
import i18n from "./locales/i18n";

import App from "./App.vue";
import router from "./router";

import "viewerjs/dist/viewer.css";
import "./assets/style/tailwind.scss";

const app = createApp(App);
const pinia = createPinia();

app.use(router);
app.use(pinia);
app.use(i18n);

app.mount("#app");

export default app;
