import { onMounted, onUpdated, Ref } from "vue";
import { useTO } from "~/locales/i18n";
import { requiredValidator } from "./required.validator";

export interface ValidatorResponse {
  errorKey: string;
  arg?: any;
}

export type ValidatorFn = (...args: any) => ValidatorResponse;

export interface InputComponentProps {
  value: any;
  required: boolean;
  validation?: ValidatorFn[];
}

export function validate(props: InputComponentProps, pristine: boolean) {
  const { t } = useTO();
  let currentError = "";

  if (!props.required && pristine) {
    return currentError;
  }

  const requiredValidationResult = requiredValidator(props.required)(
    props.value
  );
  if (requiredValidationResult.errorKey) {
    return t(requiredValidationResult.errorKey);
  }

  if (!props.validation) {
    return "";
  }

  // overwrite any errors chronologically using the validation functions
  // => priority is ascending
  for (const validator of props.validation) {
    const validationResult = validator(props.value);

    if (validationResult.errorKey) {
      currentError = t(validationResult.errorKey, [validationResult.arg]);
    }
  }

  return currentError;
}

export function initValidators(
  props: Ref<InputComponentProps>,
  error: Ref<string>,
  pristine: Ref<boolean>
) {
  onMounted(() => {
    validateInput(props, error, pristine);
  });

  onUpdated(() => {
    validateInput(props, error, pristine);
  });
}

export function validateInput(
  props: Ref<InputComponentProps>,
  error: Ref<string>,
  pristine: Ref<boolean>
) {
  error.value = validate(props.value, pristine.value);
}
