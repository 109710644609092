<template>
  <div
    class="hidden sm:fixed bottom-0 left-0 sm:flex justify-between w-full text-10 px-4"
  >
    <div>{{ getSmiley(left) }}</div>
    <div>{{ getSmiley(right) }}</div>
  </div>
</template>

<script setup lang="ts">
const left = getRandomInt(2);
const right = getRandomInt(2);
function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

function getSmiley(randomNumber: number) {
  return randomNumber === 0 ? ":)" : ":(";
}
</script>
