import { defineStore } from "pinia";
import Swiper from "swiper";
import { useNotificationStore } from "~/components/notification/+notification.store";
import {
  CommentFilter,
  memeCategories,
  Post,
  PostFilter,
  UserComment,
} from "~/lib/db/post.types";
import { handleLocalVoting } from "~/lib/points";
import { useTO } from "~/locales/i18n";
import { ITEM_BATCH_AMOUNT, useSupabase } from "~/plugins/supabase";

export interface Sorting {
  byDate?: boolean;
  byPoints?: boolean;
}

interface MemeStore {
  loading: boolean;
  memes: Post[];
  currentMemeId: string;
  topEnd: boolean;
  bottomEnd: boolean;

  swiperInstance?: Swiper;
  initialSlide: number;
  showMobileFiltering: boolean;
  showPostContextMenu: boolean;
  showCommentSection: boolean;

  commentsLoading: boolean;
  comments: UserComment[];
  commentsEnd: boolean;
  writingComment: boolean;

  showPosts: boolean;
  filterTouched: boolean;
  filter: {
    mode: "posts" | "comments";
    comments: CommentFilter;
    posts: PostFilter;
  };
  sorting: Sorting;
}

function defaultCommentFilter(): CommentFilter {
  return {
    username: "",
    vote: undefined,
  };
}

function defaultPostFilter(): PostFilter {
  return {
    username: "",
    categories: memeCategories,
    vote: undefined,
  };
}

function defaultSorting(): Sorting {
  return {
    byDate: true,
    byPoints: undefined,
  };
}

export const useMemeStore = defineStore("memes", {
  state: (): MemeStore => ({
    loading: false,
    memes: [],
    currentMemeId: "",
    topEnd: false,
    bottomEnd: false,

    swiperInstance: undefined,
    initialSlide: -1,
    showMobileFiltering: false,
    showPostContextMenu: false,
    showCommentSection: false,

    commentsLoading: false,
    comments: [],
    commentsEnd: false,
    writingComment: false,

    showPosts: true,
    filterTouched: false,
    filter: {
      mode: "posts",
      comments: defaultCommentFilter(),
      posts: defaultPostFilter(),
    },
    sorting: defaultSorting(),
  }),

  getters: {
    currentMeme(): Post | undefined {
      return this.memes.find((meme) => meme.id === this.currentMemeId);
    },
  },

  actions: {
    async getPost(postId: string) {
      this.loading = true;

      try {
        const post = await useSupabase().getPost(postId);

        this.memes = post ? [post] : [];
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getInitialPosts() {
      this.loading = true;

      try {
        this.memes = await useSupabase().getPosts(
          undefined,
          this.filter.posts,
          this.sorting,
          undefined
        );
        this.initialSlide = 0;
        this.topEnd = true;
        this.bottomEnd = this.memes.length < ITEM_BATCH_AMOUNT;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getComments() {
      this.loading = true;

      try {
        const newComments = await useSupabase().getComments(
          this.sorting?.byDate !== undefined
            ? this.comments.length
              ? this.comments[this.comments.length - 1].createdAt
              : undefined
            : undefined,
          undefined,
          this.filter.comments,
          this.sorting,
          this.sorting?.byPoints !== undefined
            ? this.comments.length
            : undefined
        );

        this.comments.push(...newComments);
        this.commentsEnd = newComments.length < ITEM_BATCH_AMOUNT;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getPostsForMobile({ insertAbove }: { insertAbove: boolean }) {
      if (this.loading) return;
      if (insertAbove && this.topEnd) return;
      if (!insertAbove && this.bottomEnd) return;

      this.loading = true;
      this.sorting.byDate = insertAbove ? false : true;

      try {
        const newMemes = await useSupabase().getPosts(
          this.sorting?.byDate !== undefined
            ? this.memes.length
              ? insertAbove
                ? this.memes[0].createdAt
                : this.memes[this.memes.length - 1].createdAt
              : undefined
            : undefined,
          this.filter.posts,
          this.sorting,
          this.sorting?.byPoints !== undefined ? this.memes.length : undefined
        );
        if (newMemes.length < ITEM_BATCH_AMOUNT) {
          if (insertAbove) {
            this.topEnd = true;
          } else {
            this.bottomEnd = true;
          }
        }

        for (const [index, meme] of newMemes.entries()) {
          if (insertAbove) {
            this.memes.unshift(meme);
          } else {
            this.memes.push(meme);
          }

          if (!this.swiperInstance) continue;

          const newMemeIndex =
            this.currentMemeId === "end-reached"
              ? index + 1
              : this.memes.findIndex((meme) => meme.id === this.currentMemeId);
          this.swiperInstance.activeIndex = newMemeIndex;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    slideSwiperTo(postId: string) {
      if (!this.swiperInstance) return;

      if (postId === "end-reached") {
        this.swiperInstance.slideTo(this.swiperInstance.slides.length - 1);
        return;
      }

      const newMemeIndex = this.memes.findIndex((meme) => meme.id === postId);
      this.swiperInstance.slideTo(newMemeIndex);
    },

    async getSurroundingPosts(postId: string) {
      this.memes = await useSupabase().getSurroundingPosts(
        postId,
        this.filter.posts,
        this.sorting,
        this.sorting?.byPoints !== undefined ? this.memes.length : undefined
      );
    },

    addMemes(posts: Post[]) {
      this.memes = [...this.memes, ...posts];
    },

    async updateMeme(postId: string) {
      const supabase = useSupabase();
      const updatedPost = await supabase.getPost(postId);

      if (!updatedPost) return;

      const index = this.getMemeIndex(postId);
      if (index === -1) return;

      this.memes[index] = {
        ...updatedPost,
      };
    },

    async updatePostTitle(postId: string, newTitle: string) {
      try {
        await useSupabase().updatePostTitle(postId, newTitle);
        await this.updateMeme(postId);
        close();
      } catch (e) {
        return (e as Error).message;
      }

      return "";
    },

    async deletePost(postId: string, memePath: string) {
      const { t } = useTO();

      try {
        const index = this.memes.findIndex((meme) => meme.id === postId);
        if (index < 0)
          throw new Error("something went wrong, please reload the page");

        await useSupabase().deletePost(postId, memePath);
        this.memes.splice(index, 1);

        useNotificationStore().addSuccessNotification(
          t("post.deleteNotification")
        );
      } catch (e) {
        console.error((e as Error).message);
        useNotificationStore().addErrorNotification();
      }
    },

    updateVoteOfMeme(postId: string, upvote: boolean) {
      const index = this.getMemeIndex(postId);
      if (index === -1) return;

      const meme = this.memes[index];
      const { upvotes, downvotes, userVote, points } = handleLocalVoting(
        meme.userVote,
        upvote,
        meme.upvotes,
        meme.downvotes,
        meme.points,
        "post"
      );

      this.memes[index].upvotes = upvotes;
      this.memes[index].downvotes = downvotes;
      this.memes[index].userVote = userVote;
      this.memes[index].points = points;
    },

    async updateComments(postId: string): Promise<UserComment[]> {
      const index = this.getMemeIndex(postId);
      if (index === -1) return [];

      const supabase = useSupabase();
      const comments = await supabase.getCommentsByPost(postId);
      this.memes[index].commentCount = await supabase.getCommentCount(postId);

      return comments;
    },

    getMemeIndex(postId: string) {
      return this.memes.findIndex((post) => post.id === postId);
    },

    switchMode(showPosts: boolean) {
      const username = this.filter.posts
        ? this.filter.posts.username
        : this.filter.comments?.username;

      this.resetFilters();

      this.filter.posts.username = username;
      this.filter.comments.username = username;

      this.filter.mode = showPosts ? "posts" : "comments";
      this.filterTouched = true;
    },

    reset() {
      this.memes = [];
      this.topEnd = true;
      this.bottomEnd = true;

      this.comments = [];
      this.commentsEnd = false;
    },

    async applyFiltersMobile() {
      this.showMobileFiltering = false;
      if (this.filter.mode === "posts") {
        await this.getInitialPosts();
        this.swiperInstance?.slideTo(0);
        this.topEnd = true;
        this.bottomEnd = this.memes.length < 10;

        if (this.swiperInstance) {
          this.swiperInstance.activeIndex = 0;
          this.initialSlide = 0;
          this.currentMemeId = this.memes.length ? this.memes[0].id : "";
        }
      } else {
        this.comments = [];
        await this.getComments();
      }
    },

    resetFilters() {
      this.filterTouched = false;
      this.filter = {
        mode: "posts",
        comments: defaultCommentFilter(),
        posts: defaultPostFilter(),
      };
      this.sorting = defaultSorting();
    },
  },
});
