<template>
  <div class="relative flex gap-4 max-w-full overflow-hidden">
    <div
      class="grow flex gap-8 p-4 border rounded-sm max-w-full"
      :class="
        !isSelected
          ? memeReady
            ? 'border-dark-lighter bg-dark-lighter hover:brightness-110 hover:cursor-pointer'
            : 'border-error bg-dark-lighter'
          : 'bg-off-white border-off-white'
      "
      @click="selectMeme(index)"
    >
      <MemePreview
        :file="meme.file"
        class="shrink-0 w-80 h-80 border rounded-sm"
        :class="isSelected ? 'border-dark' : 'border-transparent'"
        :video-options="{ controls: false }"
      />

      <div class="grow flex gap-4">
        <div
          class="grow leading-s max-w-full"
          :class="isSelected && 'bg-off-white text-dark'"
        >
          <div
            class="text-14 sm:text-16 font-bold break-all text-ellipsis"
            :class="!meme.title && 'text-error'"
          >
            {{ meme.title }}
          </div>

          <div v-if="meme.tags.length" class="flex mt-4">
            <template v-for="(tag, j) in meme.tags" :key="j">
              <NsfwTag v-if="tag === 'nsfw'" />

              <PostTag
                v-else
                :borderColor="isSelected ? 'border-dark' : 'border-off-white'"
              >
                {{ tag }}
              </PostTag>
            </template>
          </div>

          <div
            v-if="meme.error"
            class="flex items-center gap-8 w-full leading-s text-14 text-error font-bold mt-32"
          >
            <IconWarning class="h-16 w-16" />

            {{ meme.error }}
          </div>
        </div>
      </div>

      <div @click="removeMeme($event, index)">
        <IconButton>
          <IconClose
            class="h-16 w-16"
            :class="isSelected ? 'text-dark' : 'text-off-white'"
          />
        </IconButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  MemeData,
  memeIsReady,
  useNewMemesStore,
} from "~/components/modal/meme/+new-memes.store";

import IconClose from "~/assets/icons/icon-close.vue";
import IconWarning from "~/assets/icons/icon-warning.vue";
import NsfwTag from "~/components/post/nsfw-tag.vue";
import PostTag from "~/components/post/post-tag.vue";
import IconButton from "~/components/ui/icon-button.vue";
import MemePreview from "./meme-preview.vue";

const props = defineProps<{ meme: MemeData; index: number }>();

const emit = defineEmits(["select"]);

const newMemesStore = useNewMemesStore();

const memeReady = computed(() => memeIsReady(props.meme));
const isSelected = computed(
  () => props.index === newMemesStore.currentMemeIndex
);

function selectMeme(index: number) {
  newMemesStore.selectMeme(index);
  emit("select");
}

function removeMeme(e: MouseEvent, index: number) {
  e.stopPropagation();
  newMemesStore.removeMeme(index);
}
</script>
