import { createI18n, useI18n } from "vue-i18n";
import en from "~/locales/en.json";

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en",
  messages: {
    en,
  },
});

export function useT() {
  return useI18n();
}

export function useTO() {
  return i18n.global;
}

export default i18n;
