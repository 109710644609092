import { defineStore } from "pinia";
import { RouteLocationNormalized, Router } from "vue-router";
import { useNotificationStore } from "~/components/notification/+notification.store";
import { useTO } from "~/locales/i18n";
import { useSupabase } from "~/plugins/supabase";

export interface MemeData {
  title: string;
  file: File;
  tags: string[];
  error?: string;
}

interface MemeStore {
  showModal: boolean;
  loading: boolean;

  currentMemeIndex: number;
  form: {
    memes: MemeData[];
    isValid: boolean;
  };
  uploadedMemes: number;
  processedMemes: number;
}

export const useNewMemesStore = defineStore("newMemes", {
  state: (): MemeStore => ({
    showModal: false,
    loading: false,

    currentMemeIndex: 0,
    form: {
      memes: [],
      isValid: false,
    },
    uploadedMemes: 0,
    processedMemes: 0,
  }),

  getters: {
    currentMeme(): MemeData | undefined {
      return this.form.memes.length
        ? this.form.memes[this.currentMemeIndex]
        : undefined;
    },

    allMemesAreReady(): boolean {
      return this.form.memes.every((meme) => memeIsReady(meme));
    },

    formIsValid(): boolean {
      return this.form.isValid && this.allMemesAreReady;
    },
  },

  actions: {
    addMemeFiles(files: File[]) {
      this.form.memes = [
        ...this.form.memes,
        ...files.map((file) => ({
          title: "",
          file,
          tags: [],
        })),
      ];
    },

    selectMeme(index: number) {
      this.currentMemeIndex = index;
    },

    removeMeme(index: number) {
      this.form.memes.splice(index, 1);
      this.currentMemeIndex = 0;
    },

    removeMemes() {
      this.form.memes = [];
      this.currentMemeIndex = 0;
    },

    clearErrors() {
      this.form.memes = this.form.memes.map((meme) => ({
        ...meme,
        error: undefined,
      }));
    },

    async postMemes(route: RouteLocationNormalized, router: Router) {
      const supabase = useSupabase();
      const { t } = useTO();
      this.loading = true;

      this.clearErrors();

      const memes = [...this.form.memes];
      for (const [i, meme] of this.form.memes.entries()) {
        try {
          await supabase.insertPost({
            file: meme.file,
            title: meme.title.trim(),
            tags: meme.tags,
          });
          this.uploadedMemes += 1;
        } catch (e) {
          memes[i].error = (e as Error).message;

          continue;
        } finally {
          this.processedMemes += 1;
        }
      }
      const memesWithErrors = memes.filter((meme) => !!meme.error);
      this.form.memes = [...memesWithErrors];
      this.currentMemeIndex = 0;

      if (memesWithErrors.length) {
        useNotificationStore().addErrorNotification(
          t("notifications.posting.error", {
            n: memesWithErrors.length,
          })
        );
      } else {
        this.showModal = false;
      }

      if (this.uploadedMemes > 0) {
        useNotificationStore().addSuccessNotification(
          t("notifications.posting.success.message", {
            n: this.uploadedMemes,
          }),
          {
            message: t("notifications.posting.success.cta"),
            callback: () =>
              route.name === "memes" && route.query.mode === "fresh"
                ? void router.go(0)
                : void router.push({
                    name: "memes",
                    query: { mode: "fresh" },
                  }),
          }
        );
      }

      this.uploadedMemes = 0;
      this.processedMemes = 0;
      this.loading = false;
    },
  },
});

export function memeIsReady(meme: MemeData) {
  return meme.file && meme.title;
}
