<template>
  <div class="w-full">
    <div class="flex justify-between" @click="expanded = !expanded"></div>
    <Link :url="url" :params="params" :underline="false" @click="emit('click')">
      <div>{{ label }}</div>

      <component v-if="icon" :is="icon" class="h-16 w-16" />

      <IconChevronUp
        v-if="children.length"
        class="transition-all"
        :class="!expanded && '-rotate-90'"
      />
    </Link>

    <div v-if="expanded">
      <div v-for="(child, i) in children" :key="i">
        <Link
          :url="child.url || ''"
          :show-external="true"
          class="py-20"
          @click="emit('click')"
        >
          <component :is="child.icon" />
        </Link>

        <div v-if="child.children">
          <Link
            v-for="(subchild, j) in child.children"
            :key="j"
            :url="subchild.url || ''"
            :show-external="true"
            class="py-20 border-b last:border-0 border-gray-lighter ml-32"
            @click="emit('click')"
          >
            <component :is="subchild.icon" />

            <div>{{ subchild.label }}</div>
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { HeaderRoute } from "../header-routes";

import IconChevronUp from "~/assets/icons/icon-chevron-up.vue";
import Link from "~/components/shared/link.vue";

defineProps({
  url: {
    type: String,
    required: true,
  },
  params: {
    type: Object as PropType<any>,
    default: undefined,
  },
  icon: {
    type: Object as PropType<any>,
    default: undefined,
  },
  label: {
    type: [Object, String],
    required: true,
  },
  children: {
    type: Array as PropType<HeaderRoute[]>,
    required: true,
  },
});

const emit = defineEmits(["click"]);

const expanded = ref(false);
</script>
