<template>
  <div
    ref="container"
    class="relative"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div class="w-full" :class="show && 'cursor-help'">
      <slot></slot>
    </div>

    <div
      v-if="hovering && show"
      ref="tooltipContainer"
      class="z-10 px-12 py-8 bg-dark-darkest border border-dark-lighter shadow-lg rounded-md text-14 text-center min-w-128 w-[max-content] max-w-[280px]"
    >
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { createPopper } from "@popperjs/core";
import { ref, watch } from "vue";

defineProps({
  message: {
    type: String,
    required: true,
  },
  show: {
    type: Boolean,
    default: true,
  },
});

const hovering = ref(false);
const container = ref<HTMLElement | null>(null);
const tooltipContainer = ref<HTMLElement | null>(null);
watch(
  () => tooltipContainer.value,
  (newValue) => {
    if (!newValue) return;

    createPopper(container.value!, newValue!, {
      placement: "top",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    });
  }
);
</script>
