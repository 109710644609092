<template>
  <div
    class="flex items-center gap-8 hover:cursor-pointer text-white"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
    @click="emit('update:selected', !selected)"
  >
    <div
      class="flex items-center justify-center h-20 w-20 border border-off-white rounded-sm"
    >
      <div v-if="selected" class="h-12 w-12 bg-off-white rounded-[1px]"></div>
    </div>

    <span
      v-if="$slots.default"
      class="leading-xs text-16"
      :class="{
        'text-off-white': highlight && highlightOnSelected,
      }"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps({
  selected: {
    type: Boolean,
    default: false,
  },
  highlightOnSelected: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:selected"]);

const hovering = ref(false);
const highlight = computed(() => props.selected || hovering.value);
</script>
