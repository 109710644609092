import { defineStore } from "pinia";
import { blobToSource } from "~/lib/files";
import { useAuth } from "~/plugins/auth";
import { useSupabase } from "~/plugins/supabase";
import { useModeration } from "../moderation/+moderation.store";
import { Achievement, Profile } from "./profile.types";

interface ProfileStore extends Profile {
  email: string;
  loading: boolean;
  avatar: string;
  ignoredUsers: string[];
}

export const useProfile = defineStore("profile", {
  state: (): ProfileStore => ({
    loading: true,

    id: "",
    createdAt: "",
    email: "",
    username: "",
    role: "user",
    subscription: undefined,
    customerId: undefined,
    avatarPath: "",
    avatar: "",
    bio: "",
    points: 0,
    selectedAchievement: undefined,
    achievements: [] as Achievement[],
    hugelolData: undefined,
    confirmedEmail: false,
    ignoredUsers: [],
    unreadNotifications: 0,
    settings: {
      hideComments: false,
      hideDownvotes: false,
      hideUpvotes: false,
      showNSFW: false,
      autoplay: false,
    },
  }),

  getters: {
    isModerator(): boolean {
      return this.role === "moderator" || this.role === "admin";
    },

    isAdmin(): boolean {
      return this.role === "admin";
    },
  },

  actions: {
    async setProfile(profile?: Profile) {
      this.loading = true;

      const auth = useAuth();
      const supabase = useSupabase();

      this.id = auth.userId;
      let p = profile || (await supabase.getProfile(auth.userId));

      this.createdAt = p.createdAt;
      this.email = auth.user?.email || "";
      this.username = p.username;
      this.subscription = p.subscription;
      this.customerId = p.customerId;
      this.role = p.role;
      if (this.isModerator) {
        useModeration().loadNotifications();
      }

      this.avatarPath = p.avatarPath;
      await this.getAvatar();
      this.bio = p.bio;
      this.points = p.points;
      this.selectedAchievement = p.selectedAchievement || undefined;
      this.achievements = p.achievements || [];
      this.hugelolData = p.hugelolData;
      this.confirmedEmail = p.confirmedEmail;
      this.ignoredUsers = p.ignoredUsers;
      this.unreadNotifications = p.unreadNotifications;
      this.settings = p.settings;

      this.loading = false;
    },

    async updateProfile() {
      await this.setProfile(await useSupabase().getProfile(useAuth().userId));
    },

    async getAvatar() {
      try {
        this.avatar = await useSupabase().getAvatarFromPath(this.avatarPath);
      } catch (e) {
        console.error(e);
      }
    },

    async updateProfilePicture(picture: File) {
      this.loading = true;

      try {
        const supabase = useSupabase();

        const error = await supabase.uploadProfilePicture(picture);
        if (error) {
          return error.message;
        }

        this.avatarPath = picture.name;
        this.avatar = blobToSource(picture);
      } catch (e) {
        return (e as Error).message;
      } finally {
        this.loading = false;
      }

      return "";
    },

    async updateIgnoredUsers(username: string) {
      await useSupabase().updateIgnoredUsers(username);
      await this.updateProfile();
    },

    async getPublicProfileOf(username: string) {
      if (!username) return undefined;

      return username === this.username
        ? {
            createdAt: this.createdAt,
            username: this.username,
            avatarPath: this.avatar,
            bio: this.bio,
            points: this.points,
            selectedAchievement: this.selectedAchievement,
            achievements: this.achievements,
            hugelolData: this.hugelolData,
            confirmedEmail: this.confirmedEmail,
          }
        : await useSupabase().getOtherProfile(username);
    },
  },
});
