<template>
  <img
    v-if="isImage(file.name)"
    :src="blobToSource(file)"
    class="rounded-sm object-cover sm:object-contain"
  />

  <div v-else ref="videoContainer">
    <video playsinline :controls="videoOptions.controls" class="rounded-sm">
      <source :src="blobToSource(file)" :type="`video/${suffix(file.name)}`" />
    </video>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, watch } from "vue";
import { blobToSource, isImage, suffix } from "~/lib/files";

interface VideoOptions {
  controls: boolean;
}

const props = defineProps({
  file: {
    type: Object as PropType<File>,
    required: true,
  },
  videoOptions: {
    type: Object as PropType<VideoOptions>,
    default: (): VideoOptions => ({
      controls: true,
    }),
  },
});

watch(
  () => props.file,
  (newValue) => {
    replaceVideo(blobToSource(newValue), newValue.name);
  }
);
const videoContainer = ref<HTMLDivElement | null>(null);
function replaceVideo(file: string, filename: string) {
  const container = videoContainer.value;
  if (!container) {
    return;
  }

  while (container.lastElementChild) {
    container.removeChild(container.lastElementChild);
  }

  container.appendChild(createVideoElement(file, filename));
}
function createVideoElement(file: string, filename: string) {
  const video = document.createElement("video");
  video.controls = props.videoOptions.controls;
  video.setAttribute("class", "rounded-sm");

  const source = document.createElement("source");
  source.src = file;
  source.type = `video/${suffix(filename)}`;

  video.appendChild(source);

  return video;
}
</script>
