<template>
  <footer class="shrink-0 flex justify-center w-full">
    <div
      v-if="!isMobile"
      class="flex justify-between w-full max-w-[75rem] p-8 mt-32 bg-dark-darker border-t border-dark"
    >
      <Gigalol class="h-16" />
    </div>

    <div
      v-else
      class="shrink-0 flex justify-center w-full bg-dark-darkest px-24 pb-12 pt-6 noselect"
    >
      <div class="grid grid-cols-5 gap-20 items-center w-full max-w-[480px]">
        <Link
          url="memes"
          :underline="false"
          class="flex flex-col justify-center items-center gap-2 text-10 text-center leading-xs capitalize"
        >
          <IconMemes
            :fill="['memes', 'post-detail'].includes(route.name as string)"
            class="h-24 w-24 my-4"
          />

          <div>{{ $t("x.memes") }}</div>
        </Link>

        <Link
          url="community"
          :underline="false"
          class="flex flex-col justify-center items-center gap-2 text-10 text-center leading-xs capitalize"
        >
          <IconCommunity
            :fill="!!route.name && (route.name as string).startsWith('community')"
            class="h-24 w-24 my-4"
          />

          <div>{{ $t("x.community") }}</div>
        </Link>

        <Link
          :url="!auth.authenticated ? 'login' : ''"
          class="flex justify-center w-full"
        >
          <div
            class="flex justify-center border-2 border-white rounded-lg p-2 w-full max-w-96"
            data-test="meme-button"
            @click="showNewMemesModal()"
          >
            <div
              class="flex justify-center bg-white rounded-md py-2 pr-8 pl-[9px] w-full"
            >
              <IconPlus class="h-24 w-24 text-dark" />
            </div>
          </div>
        </Link>

        <div></div>

        <div
          class="flex flex-col justify-center items-center gap-2 text-10 text-center leading-xs capitalize"
          @click="showMobileMenu = true"
        >
          <HeaderAvatar v-if="auth.authenticated" :is-mobile="true" />

          <IconMenu
            v-else
            class="h-24 w-24 my-4"
            @click="showMobileMenu = true"
          />

          <div>
            {{ $t("x.menu") }}
          </div>
        </div>
      </div>
    </div>

    <MobileMenu v-model:view="showMobileMenu" />
  </footer>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useAuth } from "~/plugins/auth";
import { useNewMemesStore } from "../modal/meme/+new-memes.store";

import Gigalol from "~/assets/icons/gigalol.vue";
import IconCommunity from "~/assets/icons/icon-community.vue";
import IconMemes from "~/assets/icons/icon-memes.vue";
import IconMenu from "~/assets/icons/icon-menu.vue";
import IconPlus from "~/assets/icons/icon-plus.vue";
import HeaderAvatar from "~/components/header/header-avatar.vue";
import MobileMenu from "~/components/header/mobile/mobile-menu.vue";
import Link from "../shared/link.vue";

const auth = useAuth();
const route = useRoute();
const newMemesStore = useNewMemesStore();

const isMobile = computed(() => screen.width < 1240);
const showMobileMenu = ref(false);

function showNewMemesModal() {
  if (!auth.authenticated) return;

  newMemesStore.showModal = true;
}
</script>
