<template>
  <div data-test="click-to-confirm" @click="handleClick">
    <div v-if="showConfirm" class="flex items-center gap-4 font-bold">
      <IconWarning class="h-12 w-12" />

      {{ $t("x.clickToConfirm") }}
    </div>

    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import IconWarning from "~/assets/icons/icon-warning.vue";

const props = defineProps({
  callback: {
    type: Function,
    required: true,
  },
  stopPropagation: {
    type: Boolean,
    default: true,
  },
});

const showConfirm = ref(false);

function handleClick(e: Event) {
  if (!showConfirm.value) {
    showConfirm.value = true;
    return;
  }

  if (props.stopPropagation) {
    e.stopPropagation();
  }

  props.callback();
}
</script>
