import { Session, User } from "@supabase/supabase-js";
import { defineStore } from "pinia";
import { useProfile } from "~/components/pages/profile/+profile.store";
import { useTO } from "~/locales/i18n";
import { useAxios } from "./axios";
import { useSupabase } from "./supabase";

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface SignupCredentials {
  email: string;
  password: string;
  username: string;
}

interface AuthStore {
  loading: boolean;
  user?: User;
  session?: Session;
}

export const useAuth = defineStore("auth", {
  state: (): AuthStore => ({
    loading: true,
    user: undefined,
    session: undefined,
  }),

  getters: {
    authenticated(): boolean {
      return !!this.user;
    },

    username(): string {
      return this.user?.user_metadata.username || "";
    },

    userId(): string {
      return this.user?.id || "";
    },
  },

  actions: {
    async load() {
      if (this.user) {
        return;
      }

      try {
        this.loading = true;

        const {
          data: { user, session },
          error,
        } = await useSupabase().client.auth.refreshSession();

        if (error) {
          useProfile().loading = false;
          return;
        }

        this.user = user!;
        this.session = session!;

        if (this.user) {
          await useProfile().setProfile();
        }
      } catch (e) {
        this.unset();
      } finally {
        this.loading = false;
      }
    },

    async login({ email, password }: LoginCredentials) {
      this.loading = true;

      try {
        const { data, error } =
          await useSupabase().client.auth.signInWithPassword({
            email,
            password,
          });
        this.user = data.user || undefined;
        this.session = data.session || undefined;

        if (error) {
          return error;
        }

        if (this.user) {
          await useProfile().setProfile();
        }
      } catch (e) {
        this.unset();
        return e as Error;
      } finally {
        this.loading = false;
      }

      return null;
    },

    unset() {
      this.user = undefined;
      this.session = undefined;
    },

    async logout() {
      await useSupabase().client.auth.signOut();

      window.location.reload();
    },

    async signup({
      email,
      password,
      username,
    }: SignupCredentials): Promise<Error | null> {
      const { t } = useTO();

      try {
        const response = await useAxios().post<{
          taken?: boolean;
          forbidden?: boolean;
        }>("/checkUsername", { username });

        if (response.data.forbidden) {
          return new Error(t("error.api.checkUsername.forbidden"));
        }

        if (response.data.taken) {
          return new Error(t("error.api.checkUsername.taken"));
        }
      } catch (error) {
        console.error(error);
      }

      const { error } = await useSupabase().client.auth.signUp({
        email,
        password,
        options: {
          data: {
            username,
          },
        },
      });

      return error;
    },

    async resendConfirmationLink({
      email,
      password,
    }: {
      email: string;
      password: string;
    }): Promise<Error | null> {
      try {
        await useAxios().post<{
          link: string;
        }>("/resend-confirmation-link", { email, password });
      } catch (error) {
        console.error(error);
        return error as Error;
      }

      return null;
    },

    async resetPassword(email: string) {
      await useSupabase().client.auth.resetPasswordForEmail(email, {
        redirectTo: `${import.meta.env.VITE_BASE_URL}/update-password`,
      });
    },

    async updatePassword(password: string) {
      await useSupabase().client.auth.updateUser({
        password,
      });
    },

    async deleteAccount(password: string) {
      const supabase = useSupabase();
      await supabase.removeOldAvatarIfExists();

      const { t } = useTO();
      const email = this.user?.email || "";

      try {
        await useAxios().post("/deleteUserFiles", {
          email,
          password,
        });
      } catch (error) {
        return new Error(t("error.api.deleteUserFiles"));
      }

      try {
        await useAxios().post("/deleteAccount", {
          email,
          password,
        });
      } catch (error) {
        return new Error(t("error.api.deleteAccount"));
      }
    },
  },
});
