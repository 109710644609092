export function handleLocalVoting(
  oldVote: boolean | null,
  upvote: boolean,
  oldUpvotes: number,
  oldDownvotes: number,
  oldPoints: number,
  type: "post" | "comment"
) {
  let upvotes = oldUpvotes;
  let downvotes = oldDownvotes;
  let userVote = null;
  let points = oldPoints;

  // hasn't voted yet
  if (oldVote === null) {
    if (upvote) {
      upvotes += 1;
      points += 1;
    } else {
      downvotes += 1;
      points -= 1;
    }
    userVote = upvote;

    return { upvotes, downvotes, userVote, points };
  }

  // has upvoted
  if (oldVote) {
    if (upvote) {
      upvotes -= 1;
      points -= 1;
    } else {
      upvotes -= 1;
      downvotes += 1;
      userVote = upvote;
      points -= 2;
    }
    // has downvoted
  } else {
    if (upvote) {
      upvotes += 1;
      downvotes -= 1;
      userVote = upvote;
      points += 2;
    } else {
      downvotes -= 1;
      points += 1;
    }
  }

  return { upvotes, downvotes, userVote, points };
}
