<template>
  <div
    class="flex justify-between items-center pl-24 lg:px-[28px] py-8 rounded-lg w-full min-h-[48px] sm:min-h-0 sm:w-[400px] backdrop-blur-xl shadow-2xl text-white"
    :class="
      notification.type === 'success' ? 'bg-[#56526a94]' : 'bg-[#a04d4d94]'
    "
  >
    <div class="flex flex-col justify-center">
      <div
        class="flex items-center gap-8 font-bold text-12 lg:text-14"
        :data-test="`${notification.type}-notification`"
      >
        {{ notification.message }}
      </div>

      <Link
        v-if="notification.cta"
        :url="notification.cta.url || ''"
        :params="notification.cta.params"
        target="_blank"
        :underline="false"
        class="mt-4 lg:mt-8 w-fit px-16 py-2 border rounded-sm text-12 lg:text-14 transition-colors hover:bg-off-white hover:text-dark cursor-pointer"
      >
        <div @click="runCallback(notification.id)" data-test="notification-cta">
          {{ notification.cta.message }}
        </div>
      </Link>
    </div>

    <IconButton class="relative mr-8" @click="remove(notification.id)">
      <IconNotificationClose class="h-20 w-20" />

      <div
        v-if="notification.type === 'success'"
        class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
      >
        <Countdown
          v-if="notification.lifespan"
          :limit="notification.lifespan"
          @finished="remove(notification.id)"
        />
      </div>
    </IconButton>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { Notification, useNotificationStore } from "./+notification.store";

import IconNotificationClose from "~/assets/icons/icon-notification-close.vue";
import Link from "../shared/link.vue";
import IconButton from "../ui/icon-button.vue";
import Countdown from "./countdown.vue";

const props = defineProps({
  notification: {
    type: Object as PropType<Notification>,
    required: true,
  },
});

const notificationStore = useNotificationStore();

function runCallback(notificationId: string) {
  if (props.notification.cta?.callback) {
    props.notification.cta.callback();
  }

  remove(notificationId);
}

function remove(notificationId: string) {
  notificationStore.removeNotification(notificationId);
}
</script>
