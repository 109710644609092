import { createRouter, createWebHistory } from "vue-router";
import { vueRoutes } from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes: [...vueRoutes],
  scrollBehavior(to, from, savedPosition) {
    let behaviour: any = {
      top: savedPosition || 0,
      left: 0,
    };

    if (to.hash) {
      behaviour = {
        ...behaviour,
        el: to.hash,
      };
    }

    return behaviour;
  },
});

export function useRouterOutsideComponent() {
  return router;
}

export default router;
