<template>
  <div class="flex flex-col relative">
    <div v-if="label" class="text-12 pl-2 mb-6">
      {{ label }}
    </div>

    <label
      class="flex items-center text-14 sm:text-16 border border-dark-lighter focus-within:border-off-white transition-colors rounded-md"
      :class="errorIsVisible && 'border-error'"
    >
      <input
        ref="input"
        :value="value"
        :type="type"
        :disabled="isDisabled"
        class="px-16 py-12 z-10 bg-transparent w-full transition-all outline-none placeholder:text-gray"
        :class="inputClasses"
        :placeholder="placeholder"
        :autofocus="autofocus"
        @focus="onFocus()"
        @input="updateValue(($event.target as any).value)"
      />

      <div v-if="$slots.default" class="flex-grow mr-16">
        <slot></slot>
      </div>
    </label>

    <div
      v-if="errorIsVisible"
      class="absolute -bottom-20 left-0 flex items-center gap-8 text-12 tracking-wide text-error font-bold whitespace-nowrap"
    >
      <IconWarning class="h-12 w-12" />

      <div>{{ error }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref, watch } from "vue";
import { bindToForm } from "~/components/ui/form/form-bind";
import {
  initValidators,
  validateInput,
  ValidatorFn,
} from "~/components/ui/form/validation";

import IconWarning from "~/assets/icons/icon-warning.vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
    validator: (v: string) => ["text", "password"].includes(v),
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  validation: {
    type: Array as PropType<ValidatorFn[]>,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: true,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  showError: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:value"]);

onMounted(() => {
  if (props.value) {
    setTouched();
  }
});

const pristine = ref(true);
const reactiveProps = computed(() => props);
const error = ref("");
const errorIsVisible = computed(
  () => !pristine.value && props.showError && error.value
);
initValidators(reactiveProps, error, pristine);

const disabledByForm = ref(false);
bindToForm({
  error,
  disabledByForm,
});

const inputClasses = computed(() => ({
  "text-transparent": isDisabled.value,
}));

const isDisabled = computed(() => props.disabled || disabledByForm.value);
watch(
  () => isDisabled.value,
  (newIsDisabled) => {
    if (newIsDisabled) {
      setUntouched();
    }
  }
);

function setUntouched() {
  pristine.value = true;
  updateValue("");
}
function setTouched() {
  pristine.value = false;
}
function onFocus() {
  setTouched();
  validateInput(reactiveProps, error, pristine);
}

function updateValue(value: string) {
  emit("update:value", value);
}

const input = ref<HTMLElement | null>(null);
function focus() {
  if (input.value) {
    input.value.focus();
  }
}
defineExpose({
  focus,
});
</script>

<style lang="scss">
input::placeholder {
  @apply text-gray;
}
</style>
