import { v4 } from "uuid";
import { supportedImageExtensions } from "./supported-extensions";

export function isImage(filename: string) {
  return supportedImageExtensions.includes(suffix(filename));
}

export function suffix(value: string) {
  return value.split(".").reverse()[0].toLowerCase();
}

export function blobToSource(file: Blob) {
  return URL.createObjectURL(file);
}

export function uuidFilename(filename: string) {
  return `${v4()}.${suffix(filename)}`;
}
