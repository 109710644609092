import { ValidatorFn, ValidatorResponse } from "./validate";

export function fileSizeValidator(maximumInMB: number): ValidatorFn {
  return (files: File[]): ValidatorResponse => {
    const response = {
      errorKey: "error.file_size_exceeded",
      arg: `${maximumInMB}MB`,
    };

    if (files.every((file) => file.size / 1024 / 1024 <= maximumInMB)) {
      response.errorKey = "";
    }

    return response;
  };
}
