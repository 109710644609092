import { ValidatorFn, ValidatorResponse } from "./validate";

export function requiredValidator(required: boolean): ValidatorFn {
  return (value: string): ValidatorResponse => {
    const response: ValidatorResponse = {
      errorKey: "error.field_required",
    };

    if (!required || typeof value === "string" ? !!value.trim() : !!value) {
      response.errorKey = "";
      response.arg = undefined;
    }

    return response;
  };
}
