import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { useProfile } from "~/components/pages/profile/+profile.store";
import { useAuth } from "~/plugins/auth";

type RestrictionLevel = "none" | "authenticated" | "moderator" | "admin";

type RouteOptions = Omit<RouteRecordRaw, "children"> & {
  restriction?: RestrictionLevel;
  props?: any;
  children?: RouteOptions[];
  beforeEnter?: (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized
  ) => void;
};

export class Route {
  options: RouteOptions;

  constructor(options: RouteOptions) {
    this.options = {
      ...options,
      restriction: options.restriction || "none",
    };
  }

  async authGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    auth = useAuth(),
    profile = useProfile()
  ) {
    await auth.load();

    if (auth.authenticated && to.name === "login") {
      return { name: "memes" };
    }

    if (this.options.restriction === "none") {
      return;
    }

    if (this.options.restriction === "authenticated" && !auth.authenticated) {
      return { name: "login" };
    }

    if (this.options.restriction === "moderator" && !profile.isModerator) {
      return { name: "not-found" };
    }

    if (this.options.restriction === "admin" && !profile.isAdmin) {
      return { name: "not-found" };
    }
  }

  toVueRoute(): RouteRecordRaw {
    if (this.options.redirect) {
      return {
        path: this.options.path,
        redirect: this.options.redirect,
      };
    }

    return {
      name: this.options.name,
      path: this.options.path,
      component: this.options.component,
      children: this.options.children
        ? this.options.children.map((child) => new Route(child).toVueRoute())
        : undefined,
      props: this.options.props,
      beforeEnter: async (to, from) => {
        await this.authGuard(to, from);

        if (this.options.beforeEnter) {
          this.options.beforeEnter(to, from);
        }
      },
    } as RouteRecordRaw;
  }
}

export const routes: RouteOptions[] = [
  {
    path: "/",
    redirect: "giga",
  },
  {
    path: "/giga",
    name: "memes",
    component: () => import("~/components/pages/memes/memes.vue"),
  },
  {
    path: "/giga/:postId",
    name: "post-detail",
    component: () => import("~/components/pages/post-detail/post-detail.vue"),
  },

  {
    path: "/community",
    name: "community",
    component: () => import("~/components/pages/community/community.vue"),
  },
  {
    path: "/community/:view",
    name: "community-view",
    component: () => import("~/components/pages/community/community.vue"),
  },

  {
    path: "/settings",
    component: () => import("~/components/pages/profile/settings/settings.vue"),
    restriction: "authenticated",
    children: [
      {
        name: "settings",
        path: "",
        component: () =>
          import("~/components/pages/profile/settings/general-settings.vue"),
      },
      {
        name: "featuresSettings",
        path: "features",
        component: () =>
          import("~/components/pages/profile/settings/features-settings.vue"),
      },
      {
        name: "communitySettings",
        path: "community",
        component: () =>
          import("~/components/pages/profile/settings/community-settings.vue"),
      },
      {
        name: "migrationSettings",
        path: "migration",
        component: () =>
          import("~/components/pages/profile/settings/migration-settings.vue"),
      },
      {
        name: "exclusive-settings",
        path: "exclusive",
        component: () =>
          import(
            "~/components/pages/profile/settings/exclusive/exclusive-settings.vue"
          ),
      },
    ],
  },

  {
    path: "/purchase-successful",
    name: "purchase-successful",
    component: () =>
      import(
        "~/components/pages/profile/settings/exclusive/purchase-successful.vue"
      ),
  },
  {
    path: "/subscription-cancelled",
    name: "subscription-cancelled",
    component: () =>
      import(
        "~/components/pages/profile/settings/exclusive/subscription-cancelled.vue"
      ),
  },

  {
    path: "/u/:username",
    name: "user-detail",
    component: () => import("~/components/pages/user-detail/user-detail.vue"),
  },
  {
    path: "/u/:username/giga/:postId",
    name: "user-detail-posts",
    component: () => import("~/components/pages/user-detail/user-detail.vue"),
  },
  {
    path: "/u/:username/comments",
    name: "user-detail-comments",
    component: () => import("~/components/pages/user-detail/user-detail.vue"),
  },

  {
    path: "/notifications",
    name: "notifications",
    component: () =>
      import("~/components/pages/notifications/notifications.vue"),
    restriction: "authenticated",
  },

  {
    path: "/about",
    component: () => import("~/components/pages/about/about.vue"),
    children: [
      {
        name: "about",
        path: "",
        component: () => import("~/components/pages/about/about-home.vue"),
      },
      {
        name: "faq",
        path: "faq",
        component: () => import("~/components/pages/about/faq.vue"),
      },
      {
        name: "terms",
        path: "terms-of-service",
        component: () =>
          import("~/components/pages/about/terms-of-service.vue"),
      },
      {
        name: "privacy",
        path: "privacy-policy",
        component: () => import("~/components/pages/about/privacy-policy.vue"),
      },
      {
        name: "imprint",
        path: "imprint",
        component: () => import("~/components/pages/about/imprint.vue"),
      },
      {
        name: "rules",
        path: "rules",
        component: () => import("~/components/pages/about/rules.vue"),
      },
      {
        name: "points",
        path: "points",
        component: () => import("~/components/pages/about/points-page.vue"),
      },
    ],
  },

  {
    path: "/moderation",
    component: () => import("~/components/pages/moderation/moderation.vue"),
    restriction: "moderator",
    children: [
      {
        name: "moderation",
        path: "",
        component: () =>
          import("~/components/pages/moderation/moderation-home.vue"),
      },
      {
        name: "reported-posts",
        path: "reported-posts",
        component: () =>
          import("~/components/pages/moderation/reported-posts.vue"),
      },
      {
        name: "reported-comments",
        path: "reported-comments",
        component: () =>
          import("~/components/pages/moderation/reported-comments.vue"),
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: () => import("~/components/pages/auth/login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("~/components/pages/auth/signup.vue"),
  },
  {
    path: "/confirm-signup",
    name: "confirmSignup",
    component: () => import("~/components/pages/auth/confirm-signup.vue"),
  },
  {
    path: "/update-password",
    name: "update-password",
    component: () => import("~/components/pages/auth/update-password.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("~/components/pages/auth/reset-password.vue"),
  },

  {
    path: "/admin",
    component: () => import("~/components/pages/admin/admin.vue"),
    restriction: "admin",
    children: [
      {
        path: "",
        name: "admin",
        component: () => import("~/components/pages/admin/admin-migration.vue"),
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("~/components/pages/not-found/not-found.vue"),
  },
];
export const asRoutes = routes.map((r) => new Route(r));
export const vueRoutes = asRoutes.map((r) => r.toVueRoute());
