<template>
  <div
    class="relative text-16 font-medium"
    :class="{ 'opacity-30 pointer-events-none': disabled }"
  >
    <slot></slot>

    <div
      ref="toggle"
      class="flex items-center cursor-pointer focus:outline-4"
      :class="[expanded && 'text-blue-darker', fitContent ? 'w-fit' : 'w-full']"
      @click="toggleDropdown()"
    >
      <slot name="toggle"></slot>
    </div>

    <div
      v-if="expanded"
      ref="dropdown"
      class="flex flex-col gap-2 p-2 bg-dark-darkest border-2 border-dark-lighter rounded-md drop-shadow-lg leading-s whitespace-nowrap z-[1001]"
      :class="[fitContent ? 'w-fit' : 'w-full']"
      @click="handleSelect()"
    >
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { createPopper, Placement } from "@popperjs/core";
import { PropType, ref, watch } from "vue";
import { handleClickBehavior } from "~/lib/click";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
  fitContent: {
    type: Boolean,
    default: true,
  },
  placement: {
    type: String as PropType<Placement>,
    default: "bottom-start",
  },
});

const emit = defineEmits(["show"]);

const expanded = ref(false);

const toggle = ref<HTMLElement | null>(null);
const dropdown = ref<HTMLElement | null>(null);
watch(
  () => dropdown.value,
  (newValue) => {
    if (!newValue) return;

    createPopper(toggle.value!, newValue!, {
      placement: props.placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    });
  }
);

function toggleDropdown() {
  expanded.value = !expanded.value;

  if (expanded.value) {
    emit("show");
  }
}

handleClickBehavior({
  toggle: toggle,
  target: dropdown,
  callbackFn: close,
});
function handleSelect() {
  if (props.closeOnSelect) {
    close();
  }
}
function close() {
  expanded.value = false;
}

defineExpose({ expanded, close });
</script>
