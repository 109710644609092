<template>
  <div class="flex flex-col items-center lg:max-h-none lg:min-h-screen w-full">
    <Header v-if="!isAuthRoute" />

    <div id="modalContainer">
      <NewMemeModal v-if="newMemesStore.showModal" />
    </div>

    <div
      id="router-container"
      class="overflow-y-auto grow w-full lg:max-w-[75rem] xl:max-w-[100rem] 2xl:max-w-[120rem] bg-dark-darker lg:bg-inherit"
      :class="[
        isAuthRoute
          ? 'h-[100svh]'
          : pwaInstalled
          ? 'mobile-height-pwa'
          : 'mobile-height-browser',
      ]"
    >
      <!-- check that this change didn't break anything -->
      <router-view :key="!isMobile ? (route.name as string): ''" />
    </div>

    <Notifications />

    <Footer v-if="!isAuthRoute" />

    <CornerSmiley />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useNewMemesStore } from "~/components/modal/meme/+new-memes.store";
import { useGeneralStore } from "./plugins/general";

import Footer from "~/components/footer/footer.vue";
import Header from "~/components/header/header.vue";
import NewMemeModal from "~/components/modal/meme/new-memes-modal.vue";
import CornerSmiley from "./components/corner-smiley/corner-smiley.vue";
import Notifications from "./components/notification/notifications.vue";

const { isMobile, pwaInstalled } = storeToRefs(useGeneralStore());

const newMemesStore = useNewMemesStore();

const route = useRoute();
const isAuthRoute = computed(() =>
  [
    "login",
    "signup",
    "confirmSignup",
    "reset-password",
    "update-password",
    "not-found",
  ].includes((route.name as string) || "")
);

onBeforeMount(async () => {
  await useGeneralStore().init(window, screen);
});
</script>

<style lang="scss">
.viewer-footer {
  @apply hidden;
}

.mobile-height-pwa {
  @media only screen and (max-width: 1239px) {
    height: calc(100vh - 60px);
  }
}

.mobile-height-browser {
  @media only screen and (max-width: 1239px) {
    height: calc(100svh - 60px);
  }
}
</style>
