import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { differenceInHours, startOfWeek, subDays } from "date-fns";
import { defineStore } from "pinia";
import { Sorting } from "~/components/pages/memes/+meme.store";
import { useProfile } from "~/components/pages/profile/+profile.store";
import {
  LeaderboardProfile,
  Profile,
  PublicProfile,
  UpdateSettingsPayload,
  UserDetails,
} from "~/components/pages/profile/profile.types";
import { Database } from "~/lib/db/db.types";
import {
  CommentFilter,
  Post,
  PostFilter,
  UserComment,
} from "~/lib/db/post.types";
import { isImage, uuidFilename } from "~/lib/files";
import { useAuth } from "./auth";
import { useAuthAxios } from "./axios";

const MAX_CACHE_TIME = "31560000";
export const ITEM_BATCH_AMOUNT = 10;

interface SupabaseStore {
  client: SupabaseClient<Database>;
}

export const useSupabase = defineStore("supabase", {
  state: (): SupabaseStore => ({
    client: createClient<Database>(
      import.meta.env.VITE_SUPABASE_URL,
      import.meta.env.VITE_SUPABASE_KEY,
      {
        realtime: {
          params: {
            eventsPerSecond: 200,
          },
        },
      }
    ),
  }),

  actions: {
    async getProfile(userId: string): Promise<Profile> {
      const { data, error } = await this.client
        .rpc("get_profile")
        .select()
        .limit(1)
        .single();

      if (error || !data) {
        throw new Error(`unable to get profile of userId ${userId}`);
      }

      return data as unknown as Profile;
    },

    async getOtherProfile(username: string) {
      const { data, error } = await this.client
        .rpc("get_profile_by_username", { user_name: username })
        .select()
        .limit(1)
        .single();

      if (error || !data) {
        throw new Error(`unable to get profile of user ${username}`);
      }

      return data as unknown as PublicProfile;
    },

    async getUserDetails(username: string) {
      const { data } = await this.client
        .rpc("get_user_details", { user_name: username })
        .throwOnError()
        .select()
        .limit(1)
        .single();

      return data as unknown as UserDetails;
    },

    async updateProfileBio(bio: string) {
      const { data, error } = await this.client
        .rpc("update_profile_bio", { new_bio: bio })
        .select()
        .limit(1)
        .single();

      if (error || !data) {
        return error.message;
      }

      useProfile().bio = bio;

      return "";
    },

    async updateProfileSelectedAchievement(userAchievementId: string | null) {
      const { data, error } = await this.client
        .rpc("update_profile_selected_achievement", {
          achievement_id: userAchievementId as any,
        })
        .select()
        .limit(1)
        .single();

      if (error || !data) {
        return error.message;
      }

      await useProfile().updateProfile();

      return "";
    },

    async updateIgnoredUsers(username: string) {
      if (!username) return;

      await this.client
        .rpc("update_ignored_users", { user_name: username })
        .throwOnError();
    },

    async updateSettings(settings: UpdateSettingsPayload) {
      await this.client
        .from("preferences")
        .update(settings)
        .eq("userId", useProfile().id)
        .throwOnError();
    },

    async fetchAvatarPath(username: string) {
      const { data, error } = await this.client
        .from("profiles")
        .select("avatarPath")
        .eq("username", username);

      if (error || !data) return "";

      return data[0].avatarPath || "";
    },

    async getAvatar(username: string) {
      const avatarPath = await this.fetchAvatarPath(username);
      if (!avatarPath) {
        return "";
      }

      return this.getAvatarFromPath(avatarPath);
    },

    async getAvatarFromPath(path: string) {
      if (!path) {
        return "";
      }

      const { data } = this.client.storage.from("avatars").getPublicUrl(path);

      return data.publicUrl;
    },

    async removeOldAvatarIfExists() {
      const avatarPath = await this.fetchAvatarPath(useAuth().username);
      if (!avatarPath) {
        return;
      }

      const avatarExists = !!(
        await this.client.storage
          .from("avatars")
          .list(undefined, { search: avatarPath })
      ).data?.length;
      if (avatarExists) {
        await this.client.storage.from("avatars").remove([avatarPath]);
      }
    },

    async uploadProfilePicture(file: File) {
      this.removeOldAvatarIfExists();

      const filePath = uuidFilename(file.name);

      const { error } = await this.client.storage
        .from("avatars")
        .upload(filePath, file, {
          cacheControl: MAX_CACHE_TIME,
        });
      if (error) {
        return error;
      }

      const { error: updateError } = await this.client.rpc(
        "update_profile_avatar_path",
        { avatar_path: filePath }
      );
      if (updateError) return updateError;

      try {
        await useAuthAxios(useAuth().session).post("/media/upload-avatar", {
          filePath,
        });
      } catch (e) {
        return e as Error;
      }
    },

    getMeme(filePath: string) {
      let bucket = "images";
      let filename = filePath;

      if (
        filePath.startsWith("hl-posts") ||
        filePath.startsWith("hl-comments")
      ) {
        const parts = filePath.split("/");
        bucket = parts[0];
        filename = parts[1];
      }

      const { data } = this.client.storage.from(bucket).getPublicUrl(filename);

      return data.publicUrl;
    },

    async deleteMeme(memePath: string) {
      if (!memePath) return;

      await useSupabase().client.storage.from("images").remove([memePath]);
    },

    async getSurroundingPosts(
      postId: string,
      filter?: PostFilter,
      sorting?: Sorting,
      offset?: number
    ) {
      const { data } = await this.client.rpc("get_surrounding_posts", {
        post_id: postId,
        user_name:
          filter?.vote === undefined ? filter?.username : filter.vote.username,
        categories: filter?.categories,
        by_points_desc: sorting?.byPoints,
        by_date_desc: sorting?.byDate,
        user_vote:
          filter?.vote === undefined ? undefined : filter.vote.userVote,
        batch_offset: offset,
      });
      return data as unknown as Post[];
    },

    async getPosts(
      lastCreatedAt?: string,
      filter?: PostFilter,
      sorting?: Sorting,
      offset?: number
    ): Promise<Post[]> {
      let query = this.client.rpc("get_posts", {
        user_name:
          filter?.vote === undefined
            ? filter?.username || undefined
            : filter.vote.username,
        before: sorting?.byDate ? lastCreatedAt : undefined,
        after: sorting?.byDate === false ? lastCreatedAt : undefined,
        batch_size: ITEM_BATCH_AMOUNT,
        categories: filter?.categories,
        by_points_desc: sorting?.byPoints,
        batch_offset: offset,
        by_date_desc: sorting?.byDate,
        user_vote:
          filter?.vote === undefined ? undefined : filter.vote.userVote,
      });

      const { data, error } = await query.select();
      if (error || !data) return [];

      return data as unknown as Post[];
    },

    async getPost(postId: string): Promise<Post | undefined> {
      const { data, error } = await this.client
        .rpc("get_posts", {
          post_ids: [postId],
          batch_size: 1,
        })
        .select()
        .limit(1)
        .single();

      if (error || !data) {
        return undefined;
      }

      return data as unknown as Post;
    },

    async insertPost({
      file,
      title,
      tags,
    }: {
      file: File;
      title: string;
      tags: string[];
    }) {
      const filePath = await this.uploadMeme(file);
      if (isImage(filePath)) {
        await useAuthAxios(useAuth().session).post("/media/compress-meme", {
          filePath,
        });
      }

      await this.client
        .rpc("insert_post", {
          title,
          meme_path: filePath,
          tag_list: tags,
        })
        .throwOnError();
    },

    async updatePostTitle(postId: string, newTitle: string) {
      const { data } = await this.client
        .rpc("update_post_title", {
          post_id: postId,
          new_title: newTitle,
        })
        .throwOnError()
        .limit(1)
        .single();

      if (!data) return undefined;

      return data;
    },

    async deletePost(postId: string, memePath: string) {
      if (!postId) return;

      await this.deleteMeme(memePath);

      await this.client.rpc("delete_post", { post_id: postId }).throwOnError();
    },

    async handleVote(
      thing: "post" | "comment",
      thingId: string,
      upvote: boolean
    ) {
      if (thing === "post") {
        await this.client.rpc("insert_vote", {
          post_id: thingId,
          user_vote: upvote,
        });
      } else {
        await this.client.rpc("insert_comment_vote", {
          comment_id: thingId,
          user_vote: upvote,
        });
      }
    },

    async getComments(
      lastCreatedAt?: string,
      parentId?: string,
      filter?: CommentFilter,
      sorting?: Sorting,
      offset?: number
    ) {
      let query = this.client.rpc("get_comments", {
        user_name:
          filter?.vote === undefined ? filter?.username : filter.vote.username,
        parent_id: parentId,
        post_id: filter?.postId,
        before: sorting?.byDate ? lastCreatedAt : undefined,
        after: sorting?.byDate === false ? lastCreatedAt : undefined,
        batch_size: ITEM_BATCH_AMOUNT,
        by_points_desc: sorting?.byPoints,
        batch_offset: offset,
        by_date_desc: sorting?.byDate,
        user_vote:
          filter?.vote === undefined ? undefined : filter.vote.userVote,
      });

      const { data, error } = await query.throwOnError().select();

      if (error || !data) return [];

      return data as unknown as UserComment[];
    },

    async getCommentsByPost(
      postId: string,
      parentId?: string
    ): Promise<UserComment[]> {
      const { data, error } = await this.client
        .rpc("get_comments", {
          post_id: postId,
          parent_id: parentId,
          by_points_desc: true,
        })
        .select();

      if (error || !data) return [];

      return data as unknown as UserComment[];
    },

    async getCommentChain({
      postId,
      commentId,
    }: {
      postId: string;
      commentId: string;
    }) {
      const { data, error } = await this.client
        .rpc("get_comment_chain", {
          post_id: postId,
          comment_id: commentId,
        })
        .select();

      if (error || !data) return [];

      return data as unknown as UserComment[];
    },

    async uploadMeme(file: File) {
      const filePath = uuidFilename(file.name);

      const { error } = await this.client.storage
        .from("images")
        .upload(filePath, file, {
          cacheControl: MAX_CACHE_TIME,
        });
      if (error) {
        throw error;
      }

      return filePath;
    },

    async insertComment({
      postId,
      parentId,
      message,
      attachment,
      isNSFW = false,
    }: {
      postId: string;
      parentId?: string;
      message: string;
      attachment: File[];
      isNSFW: boolean;
    }) {
      const attachmentPath = attachment.length
        ? await this.uploadMeme(attachment[0])
        : "";
      if (attachmentPath && isImage(attachmentPath)) {
        await useAuthAxios(useAuth().session).post("/media/compress-meme", {
          filePath: attachmentPath,
        });
      }

      await this.client
        .rpc("insert_comment", {
          post_id: postId,
          parent_id: parentId || (null as any),
          attachment_path: attachmentPath,
          message,
          is_nsfw: isNSFW,
        })
        .throwOnError();
    },

    async editComment(commentId: string, newMessage: string) {
      const { data } = await this.client
        .rpc("edit_comment", {
          comment_id: commentId,
          new_message: newMessage,
        })
        .throwOnError()
        .limit(1)
        .single();

      if (!data) return undefined;

      return data;
    },

    async deleteComment(commentId: string, memePath: string) {
      if (!commentId) return;

      await this.deleteMeme(memePath);

      await this.client
        .rpc("delete_comment", { comment_id: commentId })
        .select();
    },

    async getCommentCount(postId: string) {
      const { count, error } = await this.client
        .from("comments")
        .select("*", { count: "exact", head: true })
        .eq("postId", postId);

      if (error || count === null) {
        return 0;
      }

      return count;
    },

    async getLatestComments(lastCreatedAt?: string) {
      let query = this.client.rpc("get_comments", {
        before: lastCreatedAt,
        batch_size: 5,
      });

      const { data } = await query.throwOnError().select();

      if (!data) return [];

      return data as unknown as UserComment[];
    },

    async getTopComments(offset?: number) {
      let query = this.client.rpc("get_comments", {
        after: subDays(new Date(), 1).toISOString(),
        batch_size: 5,
        by_points_desc: true,
        batch_offset: offset,
      });

      const { data } = await query.throwOnError().select();

      if (!data) return [];

      return data as unknown as UserComment[];
    },

    async getTopUsers() {
      const now = new Date();
      const delta = differenceInHours(
        now,
        startOfWeek(now, { weekStartsOn: 1 })
      );

      const { data } = await this.client
        .rpc("get_top_users", { time_period: `${delta} hours` })
        .throwOnError()
        .select();
      if (!data) return [];

      return data as unknown as LeaderboardProfile[];
    },
  },
});
