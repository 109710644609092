<template>
  <div class="flex items-center gap-8 group" data-test="open-menu-toggle">
    <div class="cursor-pointer" :class="isMobile ? 'h-32 w-32' : 'h-40 w-40'">
      <div
        class="relative flex justify-center items-center h-full w-full rounded-full"
      >
        <IconLoading
          v-if="profile.loading"
          class="h-24 w-24 animate-spin text-dark"
        />

        <template v-else>
          <img
            v-if="profile.avatar"
            :src="profile.avatar"
            class="h-full w-full object-cover rounded-full"
          />

          <div v-else class="flex justify-center items-center h-full w-full">
            <IconProfile :class="isMobile ? 'h-24 w-24' : 'h-32 w-32'" />
          </div>
        </template>

        <NotificationBubble
          v-if="profile.unreadNotifications"
          :amount="profile.unreadNotifications"
          class="absolute -left-8 -top-8"
        />

        <NumberBubble
          v-if="profile.isModerator && moderation.notificationsAmount"
          :amount="moderation.notificationsAmount"
          class="absolute -left-8 -bottom-8 bg-green"
        />
      </div>
    </div>

    <IconButton v-if="!isMobile" class="group-hover:bg-hover">
      <IconMenu class="h-16 w-16" />
    </IconButton>
  </div>
</template>

<script setup lang="ts">
import { useModeration } from "../pages/moderation/+moderation.store";
import { useProfile } from "../pages/profile/+profile.store";

import IconLoading from "~/assets/icons/icon-loading.vue";
import IconMenu from "~/assets/icons/icon-menu.vue";
import IconProfile from "~/assets/icons/icon-profile.vue";
import NotificationBubble from "../pages/notifications/notification-bubble.vue";
import NumberBubble from "../shared/number-bubble.vue";
import IconButton from "../ui/icon-button.vue";

defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
});

const profile = useProfile();
const moderation = useModeration();
</script>
