<template>
  <div
    class="p-8 rounded-sm transition-all"
    :class="
      disabled
        ? 'text-gray pointer-events-none'
        : 'hover:bg-hover hover:cursor-pointer'
    "
    @click="onClick($event)"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);

function onClick(e: Event) {
  if (props.disabled) return;

  emit("click", e);
}
</script>
