<template>
  <Tooltip :message="$t('memes.confirmMailTooltip')" :show="disable">
    <Button :disabled="disable" @click="onClick" data-test="meme-button">
      <slot>
        {{ $t("header.meme") }}
      </slot>
    </Button>
  </Tooltip>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useNewMemesStore } from "~/components/modal/meme/+new-memes.store";
import { useAuth } from "~/plugins/auth";
import { useProfile } from "../pages/profile/+profile.store";

import Tooltip from "../tooltip/tooltip.vue";
import Button from "../ui/button.vue";

const auth = useAuth();
const newMemesStore = useNewMemesStore();

const disable = computed(
  () => auth.authenticated && !useProfile().confirmedEmail
);

const router = useRouter();
function onClick() {
  if (!auth.authenticated) {
    void router.push({ name: "login" });
    return;
  }

  newMemesStore.showModal = true;
}
</script>
