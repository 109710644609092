<template>
  <button
    :type="type"
    :disabled="disabled"
    class="flex justify-center items-center gap-8 h-32 sm:h-40 px-20 text-14 sm:text-16 rounded-md font-heading font-bold border whitespace-nowrap transition-all text-center"
    :class="[
      disabled || loading
        ? 'hover:cursor-not-allowed brightness-75'
        : 'hover:cursor-pointer',
      variantStyling,
      capitalize && 'capitalize',
    ]"
    @click="onClick"
  >
    <template v-if="icon">
      <component :is="(icon as any)"></component>
    </template>

    <slot></slot>

    <IconLoading v-if="loading" class="h-16 w-16 animate-spin ml-8" />
  </button>
</template>

<script setup lang="ts">
import { computed, PropType, VueElement } from "vue";

import IconLoading from "~/assets/icons/icon-loading.vue";

const props = defineProps({
  type: {
    type: String as PropType<"submit" | "button">,
    default: "button",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String as PropType<"primary" | "secondary" | "danger">,
    default: "primary",
  },
  icon: {
    type: Object as PropType<VueElement | undefined>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  capitalize: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["click"]);

const variantStyling = computed(() => {
  switch (props.variant) {
    case "primary":
      return "border-off-white bg-off-white text-dark hover:brightness-90";
    case "secondary":
      return "border-off-white hover:bg-hover";
    case "danger":
      return "bg-dark hover:bg-error text-error hover:text-off-white border-error";
  }
});

function onClick($event: MouseEvent) {
  if (props.disabled || props.loading) {
    $event.stopPropagation();
  }

  emit("click", $event);
}
</script>
