<template>
  <form @submit.prevent="onSubmit($event)">
    <slot></slot>

    <div
      v-if="error"
      class="flex justify-center items-center gap-8 w-full leading-s text-14 text-error font-bold mt-32"
    >
      <IconWarning class="h-16 w-16" />

      {{ error }}
    </div>
  </form>
</template>

<script setup="{ slots }" lang="ts">
import {
  onDeactivated,
  onMounted,
  provide,
  ref,
  useSlots,
  watch,
  WatchStopHandle,
} from "vue";
import { IFormInput, InputWatcher } from "./form-bind";

import IconWarning from "~/assets/icons/icon-warning.vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:isValid", "onSubmit"]);

const slots = useSlots();

const isValid = ref(false);
const watchers = ref<InputWatcher[]>([]);
const unwatchFunctions = ref<WatchStopHandle[]>([]);

provide("form", {
  bind,
  unbind,
  isValid,
});

function bind(component: IFormInput) {
  registerWatcher(component);
}

function unbind(uid: number) {
  const index = watchers.value.findIndex((w) => w.id === uid);

  if (index > -1) {
    watchers.value.splice(index, 1);
  }
}

onMounted(() => {
  if (!slots.default) {
    return;
  }
});

onDeactivated(() => {
  unwatchFunctions.value.forEach((fn) => {
    fn();
  });
});

function update() {
  const watchersWithErrors = watchers.value.filter((w) => w.error !== "");

  if (watchersWithErrors.length > 0) {
    isValid.value = false;
  } else {
    isValid.value = true;
  }

  emit("update:isValid", isValid.value);
}

function onSubmit(event: Event) {
  emit("onSubmit", event);
}

function registerWatcher(component: IFormInput) {
  handleDisabled(component);

  watchers.value.push({
    id: component.uid,
    error: component.error.value,
  });

  unwatchFunctions.value.push(
    watch(
      () => component.updateCount.value,
      () => {
        const watcherIndexToUpdate = watchers.value.findIndex(
          (w) => w.id === component.uid
        );

        watchers.value[watcherIndexToUpdate].error = component.error.value;

        update();
      }
    )
  );
}

function handleDisabled(component: IFormInput) {
  component.disabledByForm.value = props.disabled;
  watch(
    () => props.disabled,
    (newValue) => {
      component.disabledByForm.value = newValue;
    }
  );
}
</script>
